
import { defineComponent, reactive, ref, watch } from 'vue'
import { getStoreTypes, IStore, getSaleTypes } from '@/interfaces/store'
import { required, stringRules } from '@/utils/formRules'
import { useResource } from '@/services'
import { updateHandler } from '@/services/updateHandler'
export default defineComponent({
  setup(props: any, context: any) {
    const dialogVisible = ref(false)
    const formCreateStore = ref()
    // let teams = ref([])
    const baseResource = 'shops'
    let storeId = ref('')
    let loading = ref(false)
    const state = reactive<{ store: IStore }>({
      store: {
        name: '',
        team: '',
        link: '',
        payment: '',
        activated: false,
        isUseDesignService: false,
        lastSyncDate: '',
        pullOrderDate: 7,
        storeType: '',
        tracking: '',
        appShopUrl: '',
        appApiKey: '',
        appPassword: '',
        appShareSecret: '',
        pullOrderTime: 1,
        saleType: '',
      },
    })
    const formRules = {
      name: stringRules('name', 2, 128),
      storeType: required('Store type'),
      appShopUrl: required('App shop url'),
      appApiKey: required('App API key'),
      appPassword: required('App password'),
    }
    const storeTypes = getStoreTypes()
    const saleTypes = getSaleTypes()
    const pullOrderDates = [
      {
        label: 'Last 1 days',
        value: 1,
      },
      {
        label: 'Last 3 days',
        value: 3,
      },
      {
        label: 'Last 5 days',
        value: 5,
      },
      {
        label: 'Last 7 days',
        value: 7,
      },
      {
        label: 'Last 21 days',
        value: 21,
      },
      {
        label: 'Last 90 days',
        value: 90,
      },
      {
        label: 'Last 120 days',
        value: 120,
      },
      {
        label: 'Last 240 days',
        value: 240,
      },
      {
        label: 'Last 360 days',
        value: 360,
      },
    ]
    const onSubmit = () => {
      formCreateStore.value.validate(async (valid: boolean) => {
        if (valid) {
          const resource = `${baseResource}/${storeId.value}`
          const { update, isLoading } = updateHandler(resource, state.store)
          loading = isLoading
          const { status } = await update()
          if (status === 200 || status === 201) {
            dialogVisible.value = !dialogVisible.value
            context.emit('onAfterUpdate')
          }
        }
      })
    }

    const toggle = (id: string) => {
      console.log('id', id)
      dialogVisible.value = !dialogVisible.value
      storeId.value = id
    }

    const fectchRecord = async (id: string) => {
      loading.value = true
      const resource = `${baseResource}`
      const { get } = useResource(resource)
      const { data, status } = await get(id)
      if (status === 200) {
        const response = data as any
        state.store = response
      }
      loading.value = false
    }

    // const getTeams = async () => {
    //   loading.value = true
    //   const resource = `seller-teams`
    //   const { getAll } = useResource(resource)
    //   const { data, status } = await getAll({})
    //   if (status === 200) {
    //     teams = data as any
    //   }
    //   loading.value = false
    // }

    const watchModalOpen = watch(dialogVisible, () => {
      if (dialogVisible.value === true) {
        fectchRecord(storeId.value)
        // getTeams()
      }
    })

    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      state,
      onSubmit,
      dialogVisible,
      toggle,
      formCreateStore,
      formRules,
      loading,
      fectchRecord,
      watchModalOpen,
      storeTypes,
      pullOrderDates,
      saleTypes,
    }
  },
})
