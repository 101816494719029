
import { computed, reactive, watch, ref, defineComponent, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData } from '@/composables'
import { formatTime } from '@/utils/dateTime'
import FilterTable from '@/components/common/FilterTable.vue'
import Pagination from '@/components/common/Pagination.vue'
import Update from './components/Update.vue'
import { useNotification } from '@/composables'
import { Edit, Refresh } from '@element-plus/icons'
import SelectSellerTeam from '@/components/common/SelectSellerTeam.vue'
import useShopRepositories from '@/repositories/useShopRepositories'

export default defineComponent({
  components: {
    sectionLayoutContent,
    FilterTable,
    Edit,
    Refresh,
    Update,
    Pagination,
    SelectSellerTeam,
  },
  setup() {
    const breadcrumbs = ['Integrations', '']
    const title = 'Integrations'
    const baseResource = 'shops'
    const titleCreate = ''
    const content = computed(() => {
      return { breadcrumbs, title, titleCreate }
    })

    let loading = ref(false)
    const { success, error } = useNotification()

    const params = reactive({
      search: null,
      country: null,
      sellerTeam: null,
      activated: null,
      saleType: '',
      page: 1,
      limit: 10,
    })

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )

    const onFilterCallback = (filter: any) => {
      params.search = filter.search
    }

    onMounted(async () => {
      await fetchData()
    })

    watch(params, fetchData)

    const columns = [
      {
        title: 'Create Date',
        dataIndex: 'createdAt',
        minWidth: '8',
      },
      {
        title: 'Type',
        dataIndex: 'storeType',
        minWidth: '5',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        minWidth: '10',
      },
      {
        title: 'Domain',
        dataIndex: 'appShopUrl',
        minWidth: '10',
      },
      {
        title: 'Status',
        dataIndex: 'activated',
        minWidth: '5',
      },
      {
        title: 'Sale type',
        dataIndex: 'saleType',
        minWidth: '5',
      },
      {
        title: 'Seller',
        dataIndex: 'sellerTeam',
        minWidth: '10',
      },
      {
        title: 'Subscription',
        dataIndex: 'subscriptionId',
        minWidth: '6',
      },
      {
        title: 'Actions',
        dataIndex: 'action',
        minWidth: '5',
        align: 'center',
      },
    ]

    const updateStoreDialog = ref<InstanceType<typeof Update>>()
    const onEditStore = (id: string) => {
      updateStoreDialog.value?.toggle(id)
    }

    const onAfterUpdate = () => {
      fetchData()
      success('Update success')
    }

    const { getStoreDomain } = useShopRepositories()
    const isFetching = ref(false)
    const fetchDomain = async (id: string) => {
      isFetching.value = true
      const res = await getStoreDomain(id)
      if (res?.status === 200) {
        success('Fetch domain success')
        fetchData()
      } else {
        error('Fetch domain error')
      }
      isFetching.value = false
    }

    return {
      fetchDomain,
      params,
      columns,
      content,
      total,
      onAfterUpdate,
      onEditStore,
      updateStoreDialog,
      documents,
      formatTime,
      isLoading,
      onFilterCallback,
      isFetching,
    }
  },
})
